<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="8"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Payments/Receipts
            </h1>
          </b-col>
        </b-row>
      </b-col>
      <!-- Content Right -->
      <b-col
        class="content-header-right text-right mb-1"
        cols="4"
      >
        <b-button
          ref="todayButton"
          variant="outline-primary"
          class="mobile_create_button mr-1"
          :class="{ 'f-white-background': !todaysTransaction, 'f-primary-background': todaysTransaction }"
          @click="changeTransactionDate"
        >
          <feather-icon
            icon="CalendarIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle d-md-block d-none">Today's Transaction</span>
          <span class="align-middle d-md-none d-block">Today's Transaction</span>
        </b-button>
        <b-button
          v-if="canViewThisAction('register-payment', 'File')"
          variant="success"
          class="mobile_create_button"
          :to="{ name: 'customers-register-payment' }"
        >
          <feather-icon
            icon="DollarSignIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle d-md-block d-none">Register Payment</span>
          <span class="align-middle d-md-none d-block">Register Payment</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="9"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Customer Name"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = '';"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="nric"
                  placeholder="NRIC"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="nric = '';"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="receiptNo"
                  placeholder="Receipt No."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="receiptNo = '';"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="fileNo"
                  placeholder="File No."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="fileNo = '';"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="lotNo"
                  placeholder="Zone/Lot No."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="lotNo = '';"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="createdBy"
                  placeholder="Created By"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="createdBy = '';"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-dropdown
                id="dropdown-form-all-receipts"
                ref="filter_dropdown_all_receipts"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                  <span class="mr-1">Filters</span>
                </template>
                <receipt-filter
                  :receipt-status-filter.sync="receiptStatusFilter"
                  :payment-mode-filter.sync="paymentModeFilter"
                  :file-type-filter.sync="fileTypeFilter"
                  :trx-date-filter.sync="trxDateFilter"
                  :customer-name.sync="customerName"
                  :customer-filter.sync="customerFilter"
                  :receipt-status-options="receiptStatusOptions"
                  :payment-mode-options="paymentModeOptions"
                  :product-options="productOptions"
                  @emitHideFilter="hideFilter"
                  @emitShowFilter="showFilter"
                />
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
        <div v-if="trxDateFilter || customerFilter || receiptStatusFilter.length || paymentModeFilter.length || fileTypeFilter.length">
          <hr class="dividerHR filter-divider">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <ul
                class="list-inline d-inline-block mb-1"
              >
                <li
                  v-for="receiptStatusVal in receiptStatusFilter"
                  :key="receiptStatusVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromReceiptStatusFilter(receiptStatusVal)"
                  >
                    <span class="text-capitalize">{{ receiptStatusVal }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-for="statusVal in paymentModeFilter"
                  :key="statusVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromPaymentModeFilter(statusVal)"
                  >
                    <span class="text-capitalize">{{ statusVal }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-for="productVal in fileTypeFilter"
                  :key="productVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFileTypeFilter(productVal)"
                  >
                    <span class="text-capitalize">{{ productVal }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-if="trxDateFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="trxDateFilter = ''"
                  >
                    {{ trxDateFilter }}
                  </b-form-tag>
                </li>

                <li
                  v-if="customerFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="customerFilter = ''; customerName = ''"
                  >
                    {{ customerName }}
                  </b-form-tag>
                </li>
                <li
                  class="list-inline-item"
                >
                  <span
                    class="text-danger clear-filter-text mb-50"
                    @click="clearAllFilters"
                  >
                    Clear Filters
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-table
        ref="refReceiptsTable"
        class="position-relative event_list min-height-200"
        :items="receiptsData"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Role -->
        <template #cell(trxID)="data">
          <div class="text-nowrap">
            <b-button
              v-if="canViewThisAction('show', 'Receipt')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="link"
              class="weight-600 align-middle detail-view-id"
              :to="{ name: 'customers-receipts-show', params: { id: data.item.trxID } }"
            >
              {{ data.item.trxNo }}
              <br>
              <b-button
                v-if="canViewThisAction('show', 'File')"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="link"
                class="weight-600 align-middle detail-view-id"
                :to="{ name: 'customers-files-show', params: { id: data.item.fileID } }"
              >
                {{ data.item.fileNo }}
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-black align-middle detail-view-id not-button"
              >
                {{ data.item.fileNo }}
              </b-button>
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-black align-middle detail-view-id not-button"
            >
              {{ data.item.trxNo }}
              <b-button
                v-if="canViewThisAction('show', 'File')"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="link"
                class="weight-600 align-middle detail-view-id"
                :to="{ name: 'customers-files-show', params: { id: data.item.fileID } }"
              >
                {{ data.item.fileNo }}
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-black align-middle detail-view-id not-button"
              >
                {{ data.item.fileNo }}
              </b-button>
            </b-button>
          </div>
        </template>

        <template #cell(nvsFileType)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.nvsFileType }}</span>
            <br>
            <span
              v-if="data.item.lotLocation"
              class=""
            >
              {{ data.item.lotLocation }}
            </span>
          </div>
        </template>

        <template #cell(purchasers)="data">
          <div
            v-for="(purchaser, key) in data.item.purchasers"
            :key="key"
            class=""
          >
            <b-button
              v-if="canViewThisAction('show', 'Customer') && purchaser.customer"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="link"
              class="weight-600 align-middle detail-view-id text-uppercase"
              :to="{ name: 'customers-customers-show', params: { id: purchaser.customer } }"
            >
              {{ purchaser.name }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-black align-middle not-button text-uppercase"
            >
              {{ purchaser.name }}
            </b-button>
            <br>
            <span
              v-if="purchaser.nric && !Array.isArray(purchaser.nric)"
              class=""
            >
              {{ purchaser.nric[0] + purchaser.nric.slice(1).replace(/.(?=.{4,}$)/g, '*') }}
            </span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveReceiptStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status.toLowerCase() }}
          </b-badge>
        </template>

        <template #cell(paymentMode)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.paymentMode }}</span>
          </div>
        </template>

        <template #cell(trxDate)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.trxDate ? dateFormat(data.item.trxDate) : '' }}</span>
          </div>
        </template>

        <template #cell(createdAt)="data">
          <div class="text-nowrap">
            <span class="">{{ getCreatedBy(data.item) }}</span>
            <br>
            <span class="">{{ getCreatedAt(data.item) }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('show', 'Receipt') || canViewThisAction('update', 'Receipt') ||
            canViewThisAction('download', 'Receipt') || canViewThisAction('send-email', 'Receipt')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="canViewThisAction('show', 'Receipt')"
              :to="{ name: 'customers-receipts-show', params: { id: data.item.trxID } }"
            >
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="canViewThisAction('update', 'Receipt')"
              @click="receiptRemarksEdit(data.item)"
            >
              <span class="align-middle ml-50">Edit Remarks</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="canViewThisAction('download', 'Receipt')"
              @click="downloadReceipt(data.item)"
            >
              <span class="align-middle ml-50">Download</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="canViewThisAction('send-email', 'Receipt')"
              @click="openEmailStatementModal(data.item)"
            >
              <span class="align-middle ml-50">Email to Customer</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalReceipts > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-show="totalReceipts > receiptsData.length"
            v-model="currentPage"
            :total-rows="totalReceipts"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

    <b-modal
      id="modal-receipts-remarks"
      size="sm"
      title="Edit Remarks"
      modal-class="status-update-modal"
      centered
      no-stacking
      header-class="front-modal"
      footer-class="iu-edit-modal-footer"
      @ok="saveReceiptRemarks"
      @cancel="closeReceiptRemarksModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="receiptRemarks"
            rows="6"
            placeholder="E.g. Payment Schedule"
          />
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!receiptRemarks"
          @click="ok()"
        >
          <span class="align-middle">Save Changes</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="email-statement-modal"
      ref="email-statement-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Email Receipt to Customer"
      @ok="sendEmailStatement"
      @cancel="closeStatementModal"
    >
      <div class="">
        <b-form-group
          label="Customer Name"
          label-for="customer-name"
          class="mb-20"
        >
          <validation-provider
            #default="{ errors }"
            name="Customer Name"
            vid="customer-name"
            rules=""
          >
            <b-form-input
              id="customer-name"
              v-model="customerNameVal"
              placeholder="Lin Chai"
              class="mb-0"
              readonly
              :state="(errors.length > 0) ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Email Address"
          label-for="customer-email"
          class="mb-20"
        >
          <validation-provider
            #default="{ errors }"
            name="Email Address"
            vid="customer-email"
            rules="required"
          >
            <b-form-input
              id="customer-email"
              v-model="customerEmailVal"
              placeholder="email@domain.com"
              class="mb-0"
              :readonly="hasEmailAddress ? true : false"
              :state="(errors.length > 0) ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Message"
          label-for="statement-message"
          class="mb-20"
        >
          <validation-provider
            #default="{ errors }"
            name="Message"
            vid="statement-message"
            rules="required"
          >
            <b-form-textarea
              id="statement-message"
              v-model="statementMessage"
              placeholder="Statement of Account"
              class="mb-0"
              rows="5"
              :state="(errors.length > 0) ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="!customerEmailVal"
          @click="ok()"
        >
          <feather-icon
            icon="SendIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Send</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormInput, BTable, BInputGroup, BInputGroupAppend, BFormTextarea,
  BBadge, BDropdown, BDropdownItem, BPagination, BButton, BFormTag, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

import store from '@/store'

import ToastificationContentCustom from '../../operation/ToastificationContentCustom.vue'

import useReceiptList from './useReceiptList'
import receiptStoreModule from './receiptStoreModule'
import ReceiptFilter from './ReceiptFilter.vue'

export default {
  components: {
    // UserListAddNew,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BFormTag,
    BFormGroup,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,

    ReceiptFilter,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      bulkStatus: 'completed',
      bulkStatusValidation: false,
      bulkStatusError: 'Valid status is required',
      paymentModeOptions: [
        { key: 'Amex Card', value: 'Amex Card' },
        { key: 'China Union Pay', value: 'China Union Pay' },
        { key: 'OCBC', value: 'OCBC' },
        { key: 'GIRO', value: 'GIRO' },
        { key: 'JCB', value: 'JCB' },
        { key: 'NETS', value: 'NETS' },
        { key: 'Credit Card', value: 'Credit Card' },
        { key: 'MASTER CARD', value: 'MASTER CARD' },
        { key: 'VISA CARD', value: 'VISA CARD' },
        { key: 'CASH', value: 'CASH' },
        { key: 'CHEQUE', value: 'CHEQUE' },
      ],
      productOptions: [
        { key: 'Little Buddha', value: 'Little Buddha' },
        { key: 'Funeral Service Package', value: 'Funeral Service Package' },
        { key: 'Niche', value: 'Niche' },
        { key: 'Pedestal', value: 'Pedestal' },
        { key: 'Urn', value: 'Urn' },
      ],
      customerName: '',
      customerOptions: [
        { label: 'Nirvana SG Users', value: 'Nirvana SG Users' },
        { label: 'Non Nirvana SG Users', value: 'Non Nirvana SG Users' },
      ],
      receiptStatusOptions: [
        { key: 'Paid', value: 'Paid' },
        { key: 'Void', value: 'Void' },
        // { key: 'Active', value: 'Active' },
      ],
      toSend: '',
      receiptRemarks: '',
      nvsReceiptID: '',
      oldRemarks: '',
      receiptID: '',
      fileID: '',
      fileNumber: '',
      fileType: '',
      nvsFileType: '',
      customerNameVal: '',
      customerEmailVal: '',
      hasEmailAddress: '',
      customerContactVal: '',
      statementMessage: '',
      receiptUpdatedAt: '',
      showFilterDropdown: process.env.VUE_APP_SHOW_FILTER_DROPDOWN,

      required,
    }
  },

  methods: {
    getCreatedBy(item) {
      if (item.createdByCustomer) {
        return item.createdByCustomer.name
      }
      if (item.createdByNvs) {
        return item.createdByNvs.name
      }
      if (item.createdBy) {
        return item.createdBy
      }

      return ''
    },
    getCreatedAt(item) {
      if (item.createdAtNvs) {
        return this.dateFormatWithTime(item.createdAtNvs)
      }
      if (item.createdAt) {
        return this.dateFormatWithFormatNTZ(item.createdAt, 'DD/MM/YYYY, hh:mma ')
      }

      return ''
    },
    hideFilter() {
      this.$refs.filter_dropdown_all_receipts.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_all_receipts.show(true)
    },
    changeTransactionDate() {
      this.$refs.todayButton.blur()
      this.todaysTransaction = !this.todaysTransaction
      this.trxDateFilter = ''
    },
    removeFileTypeFilter(item) {
      const index = this.fileTypeFilter.indexOf(item)
      if (index > -1) {
        this.fileTypeFilter.splice(index, 1)
      }
    },
    removeFromPaymentModeFilter(item) {
      const index = this.paymentModeFilter.indexOf(item)
      if (index > -1) {
        this.paymentModeFilter.splice(index, 1)
      }
    },

    removeFromReceiptStatusFilter(item) {
      const index = this.receiptStatusFilter.indexOf(item)
      if (index > -1) {
        this.receiptStatusFilter.splice(index, 1)
      }
    },

    clearAllFilters() {
      this.customerName = ''
      this.customerFilter = ''
      this.trxDateFilter = ''
      this.fileTypeFilter = []
      this.paymentModeFilter = []
      this.receiptStatusFilter = []
    },
    resolveReceiptStatusVariant(status) {
      if (status === 'Paid') return 'success'
      if (status === 'Void') return 'danger'
      return 'secondary'
    },
    receiptRemarksEdit(item) {
      this.receiptRemarks = item.remarks
      this.oldRemarks = item.remarks
      this.receiptID = item.trxID
      this.nvsReceiptID = item._id || ''
      this.fileID = item.fileID || ''
      this.fileNumber = item.fileNo || ''
      this.fileType = item.nvsFile || ''
      this.nvsFileType = item.nvsFileType || ''
      this.receiptUpdatedAt = item.updatedAt ? moment(item.updatedAt).utc().format('YYYY-MM-DDTHH:mm:ss') : null
      this.$root.$emit('bv::show::modal', 'modal-receipts-remarks', '')
    },
    saveReceiptRemarks() {
      this.$http
        .post('customer/receipts/save/remarks', {
          remarks: this.receiptRemarks,
          oldRemarks: this.oldRemarks,
          receiptID: this.receiptID,
          nvsReceiptID: this.nvsReceiptID,
          fileID: this.fileID,
          fileNo: this.fileNumber,
          nvsFileType: this.nvsFileType,
          updatedAt: this.receiptUpdatedAt,
        })
        .then(response => {
          if (response.data.success) {
            this.fetchReceipts()
            this.closeReceiptRemarksModal()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          } else {
            this.closeReceiptRemarksModal()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeReceiptRemarksModal() {
      this.$root.$emit('bv::hide::modal', 'modal-receipts-remarks', '')
    },
    downloadReceipt(item) {
      const formData = new FormData()
      formData.append('url', window.location.origin)
      formData.append('receiptID', item.trxID)
      formData.append('id', item._id)
      this.$http.post('customer/receipts/pdf/download', formData, {
        headers: { Accept: 'application/pdf' },
        responseType: 'arraybuffer',
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `Receipt-${item.trxNo}.pdf`
          link.click()

          link.remove()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    openEmailStatementModal(item) {
      this.receiptID = item.trxID
      if (item.purchasers && item.purchasers.length) {
        const firstPurchaser = item.purchasers[0]
        this.hasEmailAddress = firstPurchaser.email ? firstPurchaser.email : ''
        this.customerNameVal = firstPurchaser.name ? firstPurchaser.name : ''
        this.customerEmailVal = firstPurchaser.email ? firstPurchaser.email : ''
        this.customerContactVal = firstPurchaser.contact ? firstPurchaser.contact : ''
        this.fileType = item.nvsFileType ? item.nvsFileType : ''
        this.fileNumber = item.fileNo ? item.fileNo : ''
        this.fileID = item.fileID ? item.fileID : ''
        this.statementMessage = `Here is the Receipt for your purchase with us for ${item.nvsFileType} (file no.: ${item.fileNo}), as at ${moment().format('DD MMM YYYY')}. We appreciate your business and look forward to serving you again.`
        this.$root.$emit('bv::show::modal', 'email-statement-modal', '')
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Purchaser Data is missing',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    closeStatementModal() {
      this.$root.$emit('bv::hide::modal', 'email-statement-modal', '')
    },
    sendEmailStatement() {
      let contact = this.customerEmailVal
      if (this.customerContactVal) {
        contact = this.customerContactVal.slice(-8)
      }
      const formData = new FormData()
      formData.append('customerName', this.customerNameVal)
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('message', this.statementMessage)
      formData.append('fileType', this.fileType)
      formData.append('fileNo', this.fileNumber)
      formData.append('contact', contact)
      formData.append('fileID', this.fileID)
      formData.append('receiptID', this.receiptID)
      formData.append('url', `${window.location.origin}`)

      this.$http.post('customer/receipts/email/pdf', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.$toast({
            component: ToastificationContentCustom,
            props: {
              title: 'Receipt Sent',
              text: response.data.message,
              icon: 'CheckCircleIcon',
              variant: 'success',
              hideClose: true,
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const RECEIPT_APP_STORE_MODULE_NAME = 'app-all-receipts'

    // Register module
    if (!store.hasModule(RECEIPT_APP_STORE_MODULE_NAME)) store.registerModule(RECEIPT_APP_STORE_MODULE_NAME, receiptStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RECEIPT_APP_STORE_MODULE_NAME)) store.unregisterModule(RECEIPT_APP_STORE_MODULE_NAME)
    })

    const { canViewThisAction } = useAclUtils()

    const {
      fetchReceipts,
      tableColumns,
      perPage,
      currentPage,
      totalReceipts,
      receiptsData,
      dataMeta,
      perPageOptions,
      searchQuery,
      fileNo,
      receiptNo,
      nric,
      lotNo,
      createdBy,
      sortBy,
      isSortDirDesc,
      refReceiptsTable,
      refetchData,

      // Extra Filters
      receiptStatusFilter,
      paymentModeFilter,
      fileTypeFilter,
      trxDateFilter,
      customerFilter,
      todaysTransaction,
    } = useReceiptList()

    return {
      // Sidebar
      fetchReceipts,
      tableColumns,
      perPage,
      currentPage,
      totalReceipts,
      dataMeta,
      perPageOptions,
      searchQuery,
      receiptNo,
      fileNo,
      nric,
      lotNo,
      createdBy,
      sortBy,
      isSortDirDesc,
      refReceiptsTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      receiptsData,

      // Filter
      avatarText,
      canViewThisAction,

      // Extra Filters
      receiptStatusFilter,
      paymentModeFilter,
      fileTypeFilter,
      trxDateFilter,
      customerFilter,
      todaysTransaction,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
